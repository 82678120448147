.section {
  height: 85vh;
  position: relative;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 22px;
}
.section a:hover {
  text-decoration: underline;
}
.section > div {
  margin: auto 10vw;
}
.light {
  background-color: var(--font-color);
  color: var(--bg-color);
}
.dark {
  background-color: var(--theme-color);
  color: var(--bg-color);
}
.label {
  font-size: 17px;
}
.details {
  width: 45%;
}
.details > div {
  margin: 10vh;
}
.dark .links > h3 {
  color: var(--bg-color);
}
.links > ul > li {
  margin: 7vh 0;
}
.links li i {
  margin-right: 20px;
}
.credits {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 97vw;
  display: flex;
  margin: 0 !important;
  justify-content: center;
  font-size: 19px;
}
.credits i {
  color: red;
}
@media screen and (max-width: 500px) {
  .section {
    flex-direction: column;
    height: 100%;
    font-size: 23px;
    overflow-x: hidden;
  }
  .section > div {
    margin: 1vh 10vw 10vh 10vw;
  }
  .details > div {
    margin: 7vh 3vw;
  }
  .links i {
    width: 8%;
  }
  .links .label {
    margin-left: 13%;
  }
}
