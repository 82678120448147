.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 10vh;
  width: 100%;
}
.light {
  background-color: var(--font-color);
  color: var(--bg-color);
}
.dark {
  background-color: var(--theme-color);
  color: var(--bg-color);
}
.toggleTheme {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 35px;
  letter-spacing: 15px;
  cursor: pointer;
  margin: auto 20px;
}
.links {
  flex-grow: 1;
}
.links > ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.links > ul > li {
  margin: 2vw;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.links > ul > li:hover {
  text-decoration: underline;
}
.resume {
  font-size: 17px;
  padding: 6px 13px;
  border-radius: 5px;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume i {
  margin-right: 15px;
  font-size: 15px;
}
.resume:hover {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .navbar {
    height: 7vh;
    justify-content: center;
  }
  .links {
    display: none;
  }
}
