.section {
  padding-top: 15vh;
  height: 100%;
}
.container {
  display: flex;
  margin: 7vh 0vw 20vh 0;
  align-items: center;
  width: 100%;
}
.firsthalf {
  width: 45%;
}
.firsthalf_award {
  width: 100%;
}
.secondhalf_award {
  margin-left: 5vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.secondhalf {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.secondhalf img {
  max-width: 25vw;
  max-height: 40vh;
}
.aboutme p,
.hobbies p {
  font-size: 22px;
}
@media screen and (max-width: 500px) {
  .container {
    flex-direction: column-reverse;
    margin: 10vh 0;
  }
  .firsthalf {
    width: 100%;
  }
  .secondhalf {
    justify-content: center;
    margin-bottom: 10vh;
  }
  .secondhalf img {
    max-width: 80%;
  }
  .firsthalf_award {
    width: 100%;
  }
  .secondhalf_award {
    margin-bottom: 7vh;
  }
  .secondhalf_award img {
    height: 30vh;
  }
  .hobbies p {
    font-size: 19px;
  }
}
