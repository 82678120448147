html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 10vh;
}
h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 35px;
}
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 4vh;
  color: var(--theme-color);
}
h4 {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-size: 24px;
}
p {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 20px;
}
::-webkit-scrollbar {
  width: 8px;
  height: 100%;
}
::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 4px;
}
@media screen and (max-width: 500px) {
  h1 {
    font-weight: 700;
    font-size: 40px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
  }
  h4 {
    font-family: "IBM Plex Mono", monospace;
    font-weight: 500;
    font-size: 18px;
  }
  p {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 19px;
  }
}
