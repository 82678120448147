.container {
  padding: 10vh 0;
  display: flex;
  height: 80vh;
  justify-content: center;
}
.intro {
  padding-top: 30vh;
  font-size: 21px;
  flex-grow: 1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 35px;
}
.name {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 69px;
  margin-bottom: 10vh;
}
.brief {
  font-size: 24px;
}
.mypic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mypic img {
  max-width: 20vw;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 80vh;
    padding-bottom: 0;
  }
  .intro {
    padding-top: 10vh;
    font-size: 25px;
  }
  .mypic {
    padding-top: 6vh;
    display: flex;
    justify-content: center;
  }
  .mypic img {
    max-width: 80%;
  }
  .name {
    font-size: 6vh;
    margin-bottom: 6vh;
  }
  .brief {
    font-size: 23px;
  }
}
