.light {
  --bg-color: #ffffff;
  --font-color: #1b2430;
  --theme-color: #f24931;
}
.dark {
  --bg-color: #191919;
  --font-color: #e5e5e5;
  --theme-color: #ffd84c;
}
#app {
  background-color: var(--bg-color);
  color: var(--font-color);
}
#content {
  padding: 0 10vw;
}
@media screen and (max-width: 500px) {
  #content {
    padding: 0 8vw;
  }
}
