.button {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  place-items: center;
  cursor: pointer;
  width: auto;
  margin: 5vh 2vw 0 0;
  border: var(--theme-color) solid 3px;
}
.button:hover {
  text-decoration: underline;
}
.left i {
  margin-right: 10px;
}
.right i {
  margin-left: 10px;
}
.btn {
  display: inline-block;
}
.contained {
  background-color: var(--theme-color);
  color: var(--bg-color);
  border-color: var(--theme-color);
}
.outlined {
  color: var(--theme-color);
  background-color: rgb(0, 0, 0, 0);
}
@media screen and (max-width: 500px) {
  .button {
    font-size: 15px;
    margin-top: 2.5vh;
  }
}
